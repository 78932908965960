import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import '../components/Navbar.css';
import  Logo from"../images/logo-sakola-greens.png"


function CollapsibleExample() {
    return (
        <Navbar collapseOnSelect expand="lg" className="navbar sticky-top">
            <Container>

                <Navbar className='navbar-name'></Navbar>
                <img src={Logo}height={50} /> 
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto"></Nav>
                    <Nav>

                        <Nav.Link as={NavLink} to="/" className='navlink' activeClassName='navlink-active'>Home</Nav.Link>
                        <Nav.Link as={NavLink} to="/about" className='navlink' activeClassName='navlink-active'>About</Nav.Link>
                        <Nav.Link as={NavLink} to="/news" className='navlink' activeClassName='navlink-active'>News</Nav.Link>
                        <Nav.Link as={NavLink} to="/gallery" className='navlink' activeClassName='navlink-active'>Gallery</Nav.Link>
                        <Nav.Link as={NavLink} to="/contact" className='navlink' activeClassName='navlink-active'>Contact</Nav.Link>

                      
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CollapsibleExample;